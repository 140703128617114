// Override default variables before the import$body-bg: #000;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b;

// Themes

.main {
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}

.spaceGrey {
    @extend .main;
    background-color: #282c34;
    color: $white;
}

.shiny {
    @extend .main;
    background-color: $white;
    color: #282c34;
}

.coral {
    @extend .main;
    background-color: #f69304;
    color: #026f58;
}

// Elements

body {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.input {
    flex: 3;
    font-size: calc(10px + 2vmin);
    width: 100%;
}

.themes {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.footer p {
    flex: 1
}

input {
    flex: 1;
    font-family: inherit;
    border: 0;
    outline: 0;
    font-size: 12rem;
    background: transparent;
    transition: border-color 0.2s;
    text-align: center;
    color: inherit;
}

a {
    color: inherit;
    text-decoration: underline;
}

ul {
    list-style-type: none;
    margin: 0px;
}

img {
    width: 60%;
    margin: 2em;
}

ul > li {
    display: inline-block;
    /* You can also add some margins here to make it look prettier */
    zoom:1;
    padding-left: 15px;
    text-decoration: underline;
    cursor: pointer;
}